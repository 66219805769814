import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/dist/query/react';
import environments from "@app/configs/environments";


export const STRIPE_PATH = 'stripe';

export const stripeApi = createApi({
    reducerPath: STRIPE_PATH,
    refetchOnMountOrArgChange: true,
    refetchOnReconnect: true,
    refetchOnFocus: true,
    keepUnusedDataFor: 0,
    baseQuery: fetchBaseQuery({
        baseUrl: environments.CLIENT_SIDE_ENDPOINT_HOST + '/api',
        credentials: "include",
        prepareHeaders(headers) {
            headers.set('Access-Control-Allow-Origin', environments.CLIENT_SIDE_ENDPOINT_HOST as string);
            headers.set("Content-Type", "application/json");
            return headers;
        }
    }),
    endpoints: (builder) => ({
        createStripePayment: builder.mutation<generateStripePaymentResponse, generateStripePaymentRequest>({
            query: (request) => ({
                url: `/stripe/checkout`,
                method: 'POST',
                params: {
                    returnUrl: request.returnUrl,
                    priceId: request.priceId
                },
            })
        }),
        getBillingPortal: builder.query<getStripeBillingResponse, void>({
            query: () => '/stripe/billing-portal'
        }),
        getPaymentHistory: builder.query<Array<PaymentHistory>, void>({
            query: () => '/stripe/payment-history'
        })
    })
});

export const {useCreateStripePaymentMutation, useGetBillingPortalQuery, useLazyGetPaymentHistoryQuery} = stripeApi;
